<template>
  <v-dialog v-model="dialog" width="680" @click:outside="cancel" :persistent="isLoading">
    <v-card>
      <v-card-title>
        <h3><strong>棚卸実績CSVダウンロード</strong></h3>
      </v-card-title>
      <v-card-text>
        <span class="ml-4" v-if="errorMessage" style="color: red">
          {{ errorMessage }}
        </span>
        <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">
          {{ data.message }}
        </div>

        <v-container>
          <v-row>
            <v-col cols="12">
              <YearMonthPicker ref="nyukaFrom" v-model="searchCondition.selectedDate" label="対象年月"></YearMonthPicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="searchCondition.selectedShop" :items="shopOptions" density="compact" hide-details="auto"
                label="対象店舗" :multiple="false" item-value="code" item-title="code_name" :error-messages="errors"
                return-object></v-select>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> キャンセル </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="!isFormValid()">
          ダウンロード
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import constant from "@/utils/constant";
import YearMonthPicker from "@/components/YearMonthPicker";
import utils from "@/utils";

export default {
  components: { YearMonthPicker },
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },


  computed: {
    shopOptions() {
      return this.dialogData.shopItem;
    },
  },


  data() {
    return {
      dialog: true,
      isLoading: false,
      errorMessage: "",
      errorList: [],
      searchCondition: {
        selectedDate: null,
        selectedShop: null,
      },
      headers4MainOffice: [
        { text: '事業所コード', value: 'officeCode' },
        { text: '棚卸日', value: 'inventoryDate' },
        { text: '在庫区分', value: 'inventoryDivision' },
        { text: '倉庫コード', value: 'warehouseCode' },
        { text: '在庫場所コード', value: 'stockPlaceCode' },
        { text: 'JANCD', value: 'janCode' },
        { text: '商品コード', value: 'productCode' },
        { text: '規格', value: 'standard' },
        { text: '商品ランク', value: 'productRank' },
        { text: '棚卸記入数量', value: 'inventoryEntryQuantity' },
        { text: '売価単価', value: 'sellingPricePerUnit' },
        { text: '売価金額', value: 'sellingPriceAmount' },
      ],
      headers4General: [
        { text: 'JANCD', value: 'janCode' },
        { text: '棚卸記入数量', value: 'inventoryEntryQuantity' },
        { text: '備考', value: 'standard' },
      ],

    };
  },


  methods: {
    isFormValid() {
      return this.searchCondition.selectedDate && this.searchCondition.selectedShop;
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      api.post("/tanaoroshi/download-csv-monthly", {
        siteCode: this.searchCondition.selectedShop.code,
        targetYearMonth: this.searchCondition.selectedDate.replaceAll("-", ""),
      })
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.errorList = [];
            const useHeader = response.data.results.isMainOffice ? this.headers4MainOffice : this.headers4General;
            this.downloadCsv(useHeader, response.data.results.csvRecordList);
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          this.errorMessage = "不明なエラーが発生しました";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadCsv(header, dataList) {
      let dataArr = [];
      let arr = [];

      header.forEach(function (v) {
        arr.push(v.text)
      });
      dataArr.push(arr.join(",") + "\r\n");

      dataList.forEach(function (v, idx) {
        let arr = []
        header.forEach(function (h, i) {
          arr.push(v[h.value])
        })
        dataArr.push(arr.join(",") + "\r\n")
      })
      let stamp = utils.csvTimeStamp(new Date())
      utils.csvDownload(dataArr.join(""), `月別棚卸実績_${stamp}`)
    },
  },
};
</script>
