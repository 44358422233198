import Paths from "@/router/indexPaths"
const constant = {
  // コメントの最大横幅(文字数)
  COMMENT_MAX_WIDTH: 30,
  
  // 棚卸連携時の警告閾値(日)
  TANA_CONFIRM_WARN_THRESHOLD: 0,

  // スタッフコードの最大長(文字数)
  STAFF_CODE_MAX_LENGTH: 10,

  // プルダウン用権限リスト
  ROLE: Object.freeze({
    ADMIN: Object.freeze({ id: '0', label: 'Admin権限' }),
    ZONE_MANAGER: Object.freeze({ id: '1', label: 'ゾーンマネジャー' }),
    SITE_USER: Object.freeze({ id: '2', label: '店舗ユーザ' }),
  }),

  // メニューリスト
  // label: 画面に表示される文言
  // name:  遷移先パス
  // icon:  アイコン
  // code:  コード
  MENU_LIST: Object.freeze([
    Object.freeze({ label: "ダッシュボード", name: Paths.DASHBOARD, icon: "mdi-account-details-outline", code: "DASHBOARD", }),
    Object.freeze({ label: "ユーザ登録", name: Paths.USER, icon: "mdi-account-edit", code: "USER", }),
    Object.freeze({ label: "入荷実績", name: Paths.NYUUKA, icon: "mdi-format-list-bulleted-square", code: "NYUUKA", }),
    Object.freeze({ label: "出荷実績", name: Paths.SHUKKA, icon: "mdi-format-list-bulleted-square", code: "SHUKKA", }),
    Object.freeze({ label: "在庫", name: Paths.ZAIKO_JAN_LIST, icon: "mdi-format-list-bulleted-square", code: "ZAIKO_JAN_LIST", }),
    Object.freeze({ label: "棚卸", name: Paths.TANAOROSHI, icon: "mdi-format-list-bulleted-square", code: "TANAOROSHI", }),
    Object.freeze({ label: "在庫移動実績", name: Paths.ZAIKO_MOVE, icon: "mdi-format-list-bulleted-square", code: "ZAIKO_MOVE", }),
    Object.freeze({ label: "販売実績", name: Paths.SALES, icon: "mdi-format-list-bulleted-square", code: "SALES", }),
  ]),
  // ユーザーステータス
  USER_STATUS: Object.freeze({
    // 0:有効
    VALID: 0,
    // 1:無効
    INVALID: 1
  }),
  // レスポンスステータス
  RESPONSE_STATUS: Object.freeze({
    // 0:成功
    SUCCESS: 0,
    // 1:失敗
    FAILURE: 1
  }),
};
export default constant;
