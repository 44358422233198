
import { defineComponent } from 'vue';
import AppWrapperView from "@/components/views/AppWrapperView.vue";

export default defineComponent({
  name: 'App',
  components: {
    AppWrapperView
  },
});
