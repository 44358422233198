<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click="returnbutton">戻る</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.value" @click.stop="tableSort(header.value)"
                      :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <td v-for="header in headers" :key="header.value">{{ data[header.value] }}
                    </td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination v-model="currentPageNo" :length="maxPageNo" />
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select density="compact" variant="outlined" hide-details="auto" v-model="displayPerPage"
                    :items="displayPerPageItem" item-title="name" item-value="value" label="１ページ当たりの表示件数"
                    @update:modelValue="resetCondition()" return-object />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{ displayContentNo.minNo }} - {{ displayContentNo.maxNo }}件目 / {{ dataList.length }}件中
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn dark class="px-8 bg-indigo-darken-1" elevation="2" @click.prevent="downloadCsv"
                :disabled="dataList.length == 0">CSV出力</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { AppWrapperView, ZeroData },
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo() {
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if (maxNo > this.dataList.length || this.displayPerPage.value == 0) {
        return { minNo: minNo, maxNo: this.dataList.length };
      }
      return { minNo: minNo, maxNo: maxNo };
    },

    //最大ページ
    maxPageNo() {
      if (this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length / this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if (this.sortState.target != "") {
        tempArray = tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo - 1, this.displayContentNo.maxNo);
    },
  },


  data: () => ({
    currentPageNo: 1,
    displayPerPage: { name: "15件", value: 15 },
    displayPerPageItem: [
      { name: "5件", value: 5 },
      { name: "10件", value: 10 },
      { name: "15件", value: 15 },
      { name: "すべて", value: 0 },
    ],
    //ソートの状態
    sortState: { target: "", ascent: true },

    headers: [
      { text: 'EPC', value: 'epc' },
      { text: '担当ID', value: 'staffId' },
      { text: '移動日時', value: 'movingDateTime' },
      { text: '品番', value: 'productNumber' },
      { text: '品名', value: 'productName' },
      { text: 'JANコード', value: 'janCode' },
    ],

    dataList: [],
    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
  }),


  methods: {
    // 汎用的なソート関数を返却する
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    //カーソルの変更
    cursorCheck(value) {
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition() {
      this.currentPageNo = 1;
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if (headValue == "no") return;
      this.currentPageNo = 1;
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    search() {
      this.isSearching = true;
      const item = JSON.parse(store.getZaikoMovingResultSelectedItem());
      api.post("/zaiko/movement-actual/epc/search", {
        movingDate: item.movingDate?.replaceAll("-", ""),
        siteCode: item.siteCode,
        originSitezoneCode: item.originSitezoneCode,
        destinationSitezoneCode: item.destinationSitezoneCode,
        janCode: item.janCode,
        transactionDivision: item.transactionDivision,
      }).then((response) => {
        this.dataList = response.data.results?.zaikoEpcMovementActualResultList ?? [];
        this.resetCondition();
        if (this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      }).catch((error) => {
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    // 戻るボタン
    returnbutton() {
      router.push({ name: Paths.ZAIKO_MOVE })
    },
    // CSVダウンロード処理
    downloadCsv() {
      let dataArr = []
      let arr = []
      let head = this.headers
      head.forEach(function (v) {
        arr.push(v.text)
      })
      dataArr.push(arr.join(",") + "\r\n")
      for (const v of this.sortedTable) {
        let arr = [];
        for (const h of head) {
          arr.push(v[h.value]);
        }
        dataArr.push(arr.join(",") + "\r\n")
      }
      let stamp = utils.csvTimeStamp(new Date())
      utils.csvDownload(dataArr.join(""), `在庫EPC移動実績_${stamp}`)
    },
  },
  mounted() {
    this.search();
  }
};
</script>

<style></style>
