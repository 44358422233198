<template>
  <v-dialog v-model="dialog" width="500" @click:outside="cancel" :persistent="isLoading">
    <v-card>

      <v-alert
        v-model="alertFlg"
        type="error"
        density="compact"
        :title=errorMsg
        text="時間を置いて再度試してください。"
        closable
        class="ma-2"
      />

      <v-card-text
        class="pa-4 pre-line grey--text text--darken-4 text-subtitle-1 px-5"
      >
        <p v-if="dialogData.actionType === 0" class="mb-4">
          棚卸をクリアしますがよろしいですか？
        </p>
        <p v-if="dialogData.actionType === 1" class="mb-4">
          棚卸を確定し、上流システムへデータを連携しますがよろしいですか？
        </p>
        <p v-if="dialogData.actionType === 2" class="mb-4">
          確定します。よろしいですか？
          <br>
          <v-text style="color: #FF0000;">※APVへの連携はされません</v-text>
        </p>
        <p v-if="dialogData.actionType === 3" class="mb-4">
          確定、APVへ連携します。よろしいですか？
        </p>
        <p v-if="this.isCsvDownload()" class="mb-4">
          CSVをダウンロードします。よろしいですか？
        </p>

        <template
          v-if="dialogData.actionType === 1 || dialogData.actionType === 2 || dialogData.actionType === 3"
        >
          <div
            v-for="z in dialogData.tanaoroshi.zoneList"
            :key="z.zoneId"
            class="text-h6 d-flex justify-end mb-1"
          >
            <span>{{ z.zoneName }}</span>
            <span>{{ z.readSuuryo }} / {{ z.planSuuryo }}</span>
            <span>({{ z.readPrecision }})</span>
          </div>
          <v-text-field
            density="compact"
            variant="outlined"
            hide-details="auto"
            v-model="staffCode"
            label="確定者社員コード"
            :counter="staffCodeLength"
            :maxLength="staffCodeLength"
            :disabled="isLoading"
            :rules="[rules.checkStaffCode]"
          />
        </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" :disabled="isLoading"> キャンセル </v-btn>
          <v-btn
            color="indigo darken-1"
            dark
            @click="ok"
            :loading="isLoading"
            :disabled="invalidInput"
          >
           OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import api from "@/apis/staff";
import constant from "@/utils/constant"
import utils from "@/utils";

export default {
  props: {
    dialogData: {
      type: Object, 
      required: true, 
    },
    handleOk: {
      type: Function,
      required: true, 
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    // 連携時の警告メッセージ
    coopWarn() {
      if(this.dialogData.tanaoroshi?.confirmedDatetime == null || this.dialogData.tanaoroshi?.confirmedDatetime.length != 14) return {flag: false, date: 0}
      const warnDaySub = Math.floor((new Date() - new Date(this.dialogData.tanaoroshi.confirmedDatetime.substring(0,4), parseInt(this.dialogData.tanaoroshi.confirmedDatetime.substring(4,6)) - 1, this.dialogData.tanaoroshi.confirmedDatetime.substring(6,8))) / 86400000)
      return {
        date: warnDaySub,
        flag: warnDaySub > constant.TANA_CONFIRM_WARN_THRESHOLD ? true : false
      };
    },
    
    // validation
    invalidInput(){
      if (this.isCsvDownload()) return false;
      if (!this.isValidStaffCode(this.staffCode)) return true;
      if((this.staffCode.length > 0 && this.staffCode.length <= this.staffCodeLength)) return false;
      return true;
    },
  },
  data() {
    return {
      staffCode: "",
      dialog: true,
      isLoading: false,
      errorMsg: "",
      alertFlg: false,
      coopWarning: false,
      coopDateSub: 2,
      staffCodeLength: 0,
      rules: {
        checkStaffCode: v => {
          return this.isValidStaffCode(v) || "半角英数字で入力してください";
        },
      },
    };
  },
  watch: {
    "dialogData.tanaoroshi": function () {
      this.errorMsg = "";
      this.staffCode = "";
    },
  },
  mounted() {
    // do nothing.
    this.staffCodeLength = constant.STAFF_CODE_MAX_LENGTH;
  },
  methods: {
    isValidStaffCode(v) {
      return /^[0-9A-Za-z]*$/.test(v);
    },
    isCsvDownload() {
      return this.dialogData.actionType == 4;
    },
    cancel() {
      if(this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;

      if (this.isCsvDownload()) {
        this.downloadCsv();
        return;
      }
      //apiに突っ込む
      api.post("/tanaoroshi/confirm", {
        tanaoroshiId: this.dialogData?.tanaoroshi.tanaoroshiId ?? null,
        staffId: this.staffCode,
        shopCode: this.dialogData?.shopCode ?? "",
        releyFlag: this.dialogData?.actionType == 3 ? 1 : 0,
      })
        .then((response) => {

          this.isLoading = false;
          if(response.data?.status == 0) {
            this.errorMsg = "";
            this.handleOk(this.dialogData.actionType);
          }
          else {  
            this.errorMsg = response.data?.errorMessage;
            this.alertFlg = true;
          }

        })
        .catch((error) => {
          //do nothing
          this.isLoading = false;
          if(error.response?.status == 404) this.errorMsg = "情報が存在しません";
          else if(error.response?.status == 400) this.errorMsg = error.response.data.errorMessage;
          else if(error.response?.data?.status == 1) this.errorMsg = "エラーが発生しました"
          else this.errorMsg = "不明なエラーが発生しました";
          this.alertFlg = true;
        });
    },
    // CSVダウンロード処理
    downloadCsv() {
      api.post("/tanaoroshi/download-csv", { tanaoroshiId: this.dialogData.tanaoroshi.tanaoroshiId })
        .then((response) => {

          if (response.status == 1) return;

          let dataArr = [];
          dataArr.push(["JANCD", "数量", "備考"].join(",") + "\r\n");

          response.data.results.csvRecordList.forEach(element => {
            dataArr.push([element.janCode, element.inventoryQuantity, element.note].join(",") + "\r\n");
          });
          const stamp = utils.csvTimeStamp(new Date())
          utils.csvDownload(dataArr.join(""), `棚卸_${stamp}`);

          this.isLoading = false;
          this.errorMsg = "";
          this.handleOk(this.dialogData.actionType);
        })
        .catch((error) => {
          this.isLoading = false;this.errorMsg = "不明なエラーが発生しました";
          this.alertFlg = true;
        })
    }
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
