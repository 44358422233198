<template>
  <div>
    <v-app-bar
      color="indigo-darken-1"
      density="compact"
      theme="dark"
      clipped-left
      class="d-print-none"
    >
      <v-app-bar-nav-icon @click="visibleLeftMenu = !visibleLeftMenu">
        <v-icon>mdi-backburger</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="px-0"> </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="px-0">
      </v-toolbar-title>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              color="indigo-darken-1"
              class="btn_account"
            >
              <v-icon size="large" color="white">mdi-account</v-icon>
              <span class="text-white pl-2">{{ accountName }}</span>
            </v-btn>
          </template>
          <v-list density="compact">
            <v-list-item @click.stop="logout()">
              <v-list-item-title>
                <v-icon>mdi-exit-run</v-icon>
                ログアウト
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <slot></slot>

    <v-navigation-drawer
      class="bg-grey-darken-3 d-print-none"
      theme="dark"
      density="compact"
      clipped
      v-model="visibleLeftMenu"
    >
      <v-divider></v-divider>
      <v-list class="py-0" v-for="item in linkList" :key="item.name">
        <v-list-item :to="{ name: item.name, params: item.params }">
          <v-list-item-title>
            <v-list-item-icon class="mr-4">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import router from "@/router/index";
import store from '@/store/index';
import api from "@/apis/staff";
import Paths from "@/router/indexPaths";
import constant from "@/utils/constant";
import utils from "@/utils"

export default {
  components:{},
  props:{},
  data: () => ({
    visibleLeftMenu: null,
    accountName: store.getUserName(),
    menuList: (function(){
      let availableMenuList = store.getAvailableMenuList();
      if (availableMenuList == null) availableMenuList = [];
      return constant.MENU_LIST.filter(e => availableMenuList.indexOf(e.code) != -1);
    }()),
  }),
  computed: {
    linkList(){
      return this.menuList;
    },
  },
  methods: {
    async logout(){
      await api.get("/auth/logout")
      .then((response)=>{
        //do nothing
      })
      .catch((error) => {
        //do nothing
      });
      store.initializeSession();
      router.push({ name: Paths.LOGIN });
    },
    // メニュー表示情報の取得処理
    setMenuList() {
      // セッションに格納されているならばスキップ
      if (store.getAvailableMenuList() != null) return;
      // サーバからメニュー表示情報を取得
      api.post("/config/common/info", {})
      .then((response) => {
        this.errorMessage = utils.getErrorMsg(response)
        if (this.errorMessage != "") return;
        // セッションに格納
        store.setAvailableMenuList(response.data.results.availableMenuList);
        store.setReceiveKbnList(JSON.stringify(response.data.results.receiveKbnList));
        store.setShippedKbnList(JSON.stringify(response.data.results.shippedKbnList));
        store.setShukkaResultSpareItemMap(JSON.stringify(response.data.results.shippingResultSpareItemMap));
        this.menuList = (function () {
          const availableMenuList = store.getAvailableMenuList();
          // 表示対象外の画面を除外する
          return constant.MENU_LIST.filter(e => availableMenuList.indexOf(e.code) != -1);
        }());
        if (this.menuList.find(e => e.name == Paths.DASHBOARD)) {
          router.push({ name: Paths.DASHBOARD });
          return;
        }
      })
      .catch((error) => {
        this.errorMessage = utils.getErrorMsg(error.response)
      });
    }
  },
  mounted() {
    this.setMenuList();
  },
  beforeUnmount() {
    //do nothing.
  },
};
</script>

<style>
.btn_account {
  margin-right: -16px;
}
</style>
